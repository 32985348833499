<template>
    <div class="scanresult">
        <img :src="imgStr" class="liveimage">
        <div class="appopen" @click.stop="toAppAction">马上抢</div>
    </div>
</template>
<script>
import { isDev, requestDataTGPMethod, generateUUID, requestQueryDataTGPMethod } from "@/utils/appManager"
export default {
    name: "scanresult",
    components: {},
    data() {
        return {
            roomId: '',
            imRoomId: '',
            id: '',
            testStr: '',
            imgStr: ''
        };
    },
    methods: {
        toAppAction() {
            let ua = window.navigator.userAgent.toLowerCase();
            //判断是不是微信
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                console.log("在微信");
                this.downloadAppAction();
            } else if (ua.match(/QQ/i) == "qq") {
                console.log("在qq");
                this.downloadAppAction();
            } else {
                console.log("在app");
                let u = navigator.userAgent
                let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
                if (isAndroid) {
                    this.testStr = "https://action:" + '1964?url=https://course.tinggupiao.com.cn&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}"
                } else {
                    this.testStr = "https://action:" + '9999?url=127.0.0.1&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}"
                }
                window.location.href = this.testStr;
            }
        },
        downloadAppAction() {
            // 通用协议地址
            // iOS 下载地址
            var iOSDownloadUrl = "https://apps.apple.com/cn/app/%E5%90%AC%E8%82%A1%E7%A5%A8/id1453009085";
            // Android 下载地址
            var androidDownloadUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock"

            var commSchemeUrl = 'https://api.tinggupiao.com.cn/';
            let u = navigator.userAgent
            var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIos) {
                commSchemeUrl = commSchemeUrl + encodeURIComponent('action:9999?url=127.0.0.1&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}")
                //console.log(commSchemeUrl);
                // 
                //console.log(u.toLocaleLowerCase())
                if (u.toLocaleLowerCase().match("qq")) {
                    commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&ios_schema=" + encodeURIComponent('scxListenStock://' + 'action:9999?haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}");
                } else {

                }
                this.testStr = commSchemeUrl;
            } else {
                //console.log(commSchemeUrl);
                commSchemeUrl = "https://a.app.qq.com/o/simple.jsp?pkgname=com.company.listenStock&android_schema=" + encodeURIComponent('scxlistenstock://' + 'action:1964?url=https://course.tinggupiao.com.cn&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}");
                this.testStr = commSchemeUrl;
                //console.log(commSchemeUrl);
            }
            window.location.href = commSchemeUrl;
            // 3秒后没打开，直接跳转下载页面
            // var appDownload = setTimexout(function () {
            //     //console.log("下载app");
            //     if (isIos) {
            //         window.location.href = iOSDownloadUrl;
            //     } else {
            //         window.location.href = androidDownloadUrl;
            //     }
            // }, 3000);
            if (!isIos) {
                document.addEventListener('visibilitychange webkitvisibilitychange', function () {
                    // 页面隐藏，打开app，清除下载
                    if (document.hidden || document.webkitHidden) {
                        clearTimeout(appDownload)
                    }
                })
                window.addEventListener('pagehide', function () {
                    clearTimeout(appDownload)
                })
            }
        },
        requestLiveData() {
            //请求详情
            requestDataTGPMethod(
                "requestData",
                "v4/im/getBaseInfoByRoomId",
                { room_id: this.roomId },
                "getLiveDataSuccess",
                "0"
            );
        },
        requestBuyListData(userId) {
            requestDataTGPMethod(
                "requestData",
                "v5/orders/liveProducts",
                { liveUserId: userId },
                "buyclassListSuccess",
                "1"
            );
        },
        connectWithApp(actionType, method, param, resultMethod, haveLoad) {
            let u = navigator.userAgent
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            var uuid = "ccc1008611";
            if (isIOS) {
                window.webkit.messageHandlers.jsMessageHandler.postMessage(JSON.stringify({ 'actionType': actionType, 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestUuid': uuid }));
            } else {
                JSBridge.showMessageInNative(JSON.stringify({ 'actionType': actionType, 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestUuid': uuid }))
            }
        }
    },
    mounted() {
        console.log("scanResult")
        this.roomId = this.$route.query.roomId;
        this.imRoomId = this.$route.query.imRoomId;
        this.id = this.$route.query.id;
        console.log(this.$route.query)
        this.requestLiveData();
        this.connectWithApp("AppInfo", "AppInfo", {}, "AppInfo1", "0");
    },
    created() {
        window["getLiveDataSuccess"] = (data) => {
            console.log(data);
            this.requestBuyListData(data.data.m3u8.userId);
        }
        window["buyclassListSuccess"] = (data) => {
            console.log(data);
            var array = data.data;
            for (var i = 0; i < array.length; i++) {
                var item = array[i];
                if (item.liveProductId == this.id) {
                    this.imgStr = item.liveImage;
                }
            }
        }
        window["AppInfo1"] = (data) => {
            console.log("AppInfo1");
            this.connectWithApp("popVC", "popVC", {}, "", "0");
            console.log("在app");
            let u = navigator.userAgent
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            if (isAndroid) {
                this.testStr = "https://action:" + '1964?url=https://course.tinggupiao.com.cn&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}"
            } else {
                this.testStr = "https://action:" + '9999?url=127.0.0.1&&haveNav=0&&fileName=LivingMain&&statusColor=0Xffffff&&firstLoad={"roomId":' + this.roomId + ',"imRoomId":' + this.imRoomId + ',"id":' + this.id + ',"isBuy":1' + "}"
            }
            setTimeout(() => {
                window.location.href = this.testStr;
            }, 300);
        }
    }
};
</script>
<style scoped  lang="scss" >
.appopen {
    margin: 0 32px;
    height: 44px;
    background: #EF0923;
    border-radius: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 44px;
    text-align: center;
}

.liveimage {
    width: 100%;
}
</style>